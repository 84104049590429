import { css, html, PropertyValues, TemplateResult } from 'lit';
import { customElement, queryAssignedElements } from 'lit/decorators.js';
import { baseReset } from '../../../../libs/styles/reset';
import { HeroSlide } from '../hero-slide';
import { CalVideoPlayerComponent } from '../../../components/video-player';
import { MediaQuery, ResponsiveTargets } from '../../../styles/responsive';

@customElement('cal-video-slide')
export class CalVideoSlideComponent extends HeroSlide {
  @queryAssignedElements({ selector: 'cal-video-player' })
  videoPlayers!: CalVideoPlayerComponent[];
  videoPlayer: CalVideoPlayerComponent;

  protected firstUpdated(changedProperties: PropertyValues) {
    super.firstUpdated(changedProperties);

    if (this.videoPlayers?.length > 0) {
      this.videoPlayer = this.videoPlayers[0];
    }
  }

  onDeactivate() {
    this.pause();
  }

  pause() {
    this.videoPlayer?.pause();
  }

  protected render(): TemplateResult {
    return html`
      <div class="slide">
        <div class="slide__inner">
          <slot></slot>
        </div>
      </div>
    `;
  }

  static styles = [
    baseReset,
    ...super.slideStyles,
    css`
      .slide {
        width: 100%;
        height: 100%;
        min-height: 100vh;
      }
      
      .slide__inner {
        position: absolute;
        display: block;
        max-width: var(--content-max-width);
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        overflow: hidden;
        transform: translate(-50%, -50%);
        background-color: var(--cal-text-slide-text-backgrond-color, var(--color-grey--warm-light, #FFFFFF));
      }

      ::slotted(cal-video-player) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    `,

    MediaQuery.range(
      ResponsiveTargets.lg,
      ResponsiveTargets.xl
    )(css`
      .slide {
        min-height: 0;
      }
    `),
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    'cal-video-slide': CalVideoSlideComponent;
  }
}
